<template>
  <div class="has-text-weight-bold has-text-centered main-message">
    Verifying Email...
  </div>
</template>
<script>
import desktopUiFixes from '@utils/mixins/desktop-no-common-parts-ui-fixes.js'
import { verifyEmailAccount } from '@api/signup'
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  mixins: [desktopUiFixes],
  mounted() {
    this.verifyEmail()
  },
  methods: {
    verifyEmail() {
      const email = this.$route.query.email
      if (!email) return this.$router.replace('/login')

      verifyEmailAccount(decodeURIComponent(email)).then((res) => {
        if (!res) return
        if (res.success) {
          if (embeddedInIos()) window.webkit.messageHandlers.didSignUpEmailVerified.postMessage(true)
          else this.$router.push('/signup/profile/1')
        } else this.$router.push(`/signup/verify?email=${email}`)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.main-message {
  font-size: 20px;
  height: 100vh;
  line-height: 100vh;
}
</style>
